<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="25%"
  >
    <div class="handle-content">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="rules"
        class="demo-form-inline"
      >
        <el-form-item label="故障分析" :label-width="'90px'" prop="action">
          <el-select
            v-model="dataForm.action"
            placeholder="请选择"
            :style="{ width: '315px' }"
          >
            <el-option label="已回调，确认故障，转售后工单" :value="1"></el-option>
            <el-option label="已回调，自维修完毕，完结" :value="2"></el-option>
            <el-option label="无故障，完结" :value="3"></el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item
          label="分析原因"
          v-if="dataForm.action === 1"
          :label-width="'90px'"
          prop="suggest"
        >
          <el-select
            v-model="dataForm.suggest"
            placeholder="请选择"
            :style="{ width: '315px' }"
          >
            <el-option label="故障维修" :value="1"></el-option>
            <el-option label="外观维修" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="人员选择"
          v-if="dataForm.action === 1"
          :label-width="'90px'"
          prop="nextPerson"
        >
          <el-select
            v-model="dataForm.nextPerson"
            placeholder="请选择"
            filterable
            :style="{ width: '315px' }"
          >
            <el-option
              v-for="(item, index) in persons"
              :key="index"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item 
          label="说明"
          :label-width="'90px'"
          prop="remark"
        >
          <el-input type="textarea" placeholder="说明" v-model="dataForm.remark" :style="{ width: '315px' }"/>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button type="primary" :loading="loading" @click="dataFormSubmitHandle()">确认</el-button>
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import axios from "@/utils/axios";
export default {
  data() {
    return {
      title: null,
      loading: false,
      dataForm: {
        cloudId: null,
        action: null,
        sn: null,
        nextPerson: null,
        suggest: null,
        remark: null,
        cloudId: null,
        faultTime: null,
        orgCode: null,
        organId: null,
        id: null,
      },
      visible: false,
      persons: null,
    };
  },
  computed: {
    rules() {
      return {
        action: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        suggest: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        nextPerson: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.dataForm.organId = localStorage.getItem('organId')
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.getPersonList();
      });
    },
    //获取故障处理人列表
    getPersonList() {
      axios.post("/api/faultJob/postSalePersons", { id: this.dataForm.sn }).then(rs => {
        this.persons = rs;
      });
    },
    dataFormSubmitHandle() {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true
        axios
          .post(`/api/faultJob/deal`, this.dataForm)
          .then(res => {
            if (res) {
              this.$message({
                message: res,
                type: "success",
                duration: 2000,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
              this.loading = false
            }
          })
          .catch((err) => {
            this.loading = false
            return this.$message.error(err);
          });
      });
    },
  },
};
</script>