<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="30%"
  >
    <div class="handle-content">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="rules"
        class="demo-form-inline"
        :disabled="type===1?true:false"
      >
        <el-form-item label="设备编号" :label-width="'120px'" prop="sn">
          <el-input
            v-model="dataForm.sn"
            placeholder="设备编号"
            clearable
            :style="{ width: '315px' }"
          />
        </el-form-item>

        <el-form-item label="金额" :label-width="'120px'" prop="amount">
          <el-input
            v-model="dataForm.amount"
            placeholder="金额"
            clearable
            :style="{ width: '315px' }"
          />
        </el-form-item>
        <el-form-item label="记录说明" :label-width="'120px'" prop="remark">
          <el-input
            type="textarea"
            v-model="dataForm.remark"
            placeholder="金额"
            clearable
            :style="{ width: '315px' }"
          />
        </el-form-item>
        <el-form-item label="费用类型" :label-width="'120px'" prop="type">
          <el-select
            v-model="dataForm.type"
            placeholder="请选择"
            filterable
            :style="{ width: '315px' }"
          >
            <el-option label="物流费" :value="1"></el-option>
            <el-option label="维修费" :value="2"></el-option>
            <el-option label="配件费" :value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button type="primary" :loading="loading" v-if="type!==1" @click="dataFormSubmitHandle()">确认</el-button>
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import axios from "@/utils/axios";
export default {
  data() {
    return {
      title: null,
      loading: false,
      dataForm: {
        id: null,
        amount: null,
        occurrenceTime: null,
        organId: null,
        remark: null,
        sn: null,
        type: null,
      },
      visible: false,
      type: null,
    };
  },
  computed: {
    rules() {
      return {
        amount: [
          { required: true, message: "必填项不能为空", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "必填项不能为空", trigger: "blur" },
        ],
        sn: [{ required: true, message: "必填项不能为空", trigger: "blur" }],
        occurrenceTime: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        type: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.dataForm.organId = localStorage.getItem("organId");
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.getDataInfo();
        }
      });
    },
    getDataInfo() {
      axios.post(`/api/expenseRecord/detail`, { id: this.dataForm.id }).then((res) => {
        this.dataForm = { ...res };
      });
    },

    changeHandle() {
      let types = this.dataForm.types;
      this.dataForm.type = types.join(",");
    },
    validatePhoneNumber(rule, value, callback) {
      if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入合法的手机号"));
      }
    },
    dataFormSubmitHandle() {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true
        axios
          .post(`/api/expenseRecord/add`, this.dataForm)
          .then((res) => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
              this.loading = false
            }
          })
          .catch((err) => {
            this.loading = false
            return this.$message.error(err);
          });
      });
    },
  },
};
</script>