<template>
  <div class="back-job-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="工单信息" name="first">
        <div class="back-list-tools">
          <el-form class="battery-form" :inline="true" :model="formData">
            <el-form-item label="设备编号">
              <el-input
                v-model="formData.sn"
                placeholder="设备编号"
                size="small"
                class="area-form-item"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="当前位置">
              <el-select
                v-model="formData.curStatus"
                placeholder="当前位置"
                size="small"
                class="area-form-item"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="5级" :value="5"></el-option>
                <el-option label="4级" :value="4"></el-option>
                <el-option label="3级" :value="3"></el-option>
                <el-option label="2级" :value="2"></el-option>
                <el-option label="1级" :value="1"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="工单状态">
              <el-select
                v-model="formData.status"
                placeholder="信息状态"
                size="small"
                class="area-form-item"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="已处理" :value="1"></el-option>
                <el-option label="未处理" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发生时间:">
              <el-date-picker
                v-model="formData.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item style="float: right; marginright: 0px">
              <el-button type="primary" @click="getData()">搜索</el-button>
              <el-button @click="resetFrom()">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="back-table-container">
          <div class="back-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="设备编号" prop="sn" width="200">
                <template slot-scope="scope">
                  {{ scope.row.sn || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="派发人" prop="sender">
                <template slot-scope="scope">
                  {{ scope.row.senderText || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="派发时间" prop="sendTime" width="200">
                <template slot-scope="scope">
                  {{ scope.row.sendTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="工单状态" prop="status" width="150">
                <template slot-scope="scope">
                  {{ scope.row.status | statusStr }}
                </template>
              </el-table-column>
              <el-table-column
                label="当前设备位置"
                prop="ompStatus"
                width="220"
              >
                <template slot-scope="scope">
                  {{ scope.row.currentStatus || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="处理时间" prop="ompStatus" width="200">
                <template slot-scope="scope">
                  {{
                    scope.row.status === 1 ? scope.row.finishTime || "-" : "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="处理人" prop="ompStatus">
                <template slot-scope="scope">
                  {{ scope.row.receiverText || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="持续时间" prop="ompStatus" width="220">
                <template slot-scope="scope">
                  {{ scope.row.interval || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <!-- <el-button @click="infoHandle(scope.row)" type="text"
                    >查看</el-button
                  > -->
                  <el-button
                    v-if="scope.row.status === 0"
                    @click="setHandle(scope.row)"
                    type="text"
                    >处理</el-button
                  >
                  <el-button
                    v-if="scope.row.isBack === 0"
                    @click="backHandle(scope.row)"
                    type="text"
                    >回调</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="back-table-page">
            <div class="back-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="费用记录" name="second">
        <div class="back-list-tools">
          <el-form class="battery-form" :inline="true" :model="formData">
            <el-form-item label="设备编号">
              <el-input
                v-model="formData.sn"
                placeholder="设备编号"
                size="small"
                class="area-form-item"
                style="width: 180px"
              >
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="当前位置">
              <el-select
                v-model="formData.curStatus"
                placeholder="当前位置"
                size="small"
                class="area-form-item"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="5级" :value="5"></el-option>
                <el-option label="4级" :value="4"></el-option>
                <el-option label="3级" :value="3"></el-option>
                <el-option label="2级" :value="2"></el-option>
                <el-option label="1级" :value="1"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="费用类型">
              <el-select
                v-model="formData.type"
                placeholder="请选择"
                size="small"
                class="area-form-item"
              >
                <el-option label="全部" :value="null"></el-option>
                <el-option label="物流费" :value="1"></el-option>
                <el-option label="维修费" :value="2"></el-option>
                <el-option label="配件费" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发生时间:">
              <el-date-picker
                v-model="formData.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item style="float: right; marginright: 0px">
              <el-button type="primary" @click="getData()">搜索</el-button>
              <el-button @click="resetFrom()">重置</el-button>
            </el-form-item>
            <el-row>
              <el-form-item>
                <el-button type="primary" @click="addHandle()">新增</el-button>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
        <div class="back-table-container">
          <div class="back-table-content">
            <el-table
              style="width: 100%"
              v-loading="loading"
              height="500px"
              :data="list"
            >
              <el-table-column label="费用类型" prop="sn" width="200">
                <template slot-scope="scope">
                  {{ scope.row.type | typeStr }}
                </template>
              </el-table-column>
              <el-table-column label="记录时间" prop="sender">
                <template slot-scope="scope">
                  {{ scope.row.createTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="记录人" prop="sendTime" width="200">
                <template slot-scope="scope">
                  {{ scope.row.createBy || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="修改时间" prop="status" width="200">
                <template slot-scope="scope">
                  {{ scope.row.updateTime || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="修改人" prop="ompStatus" width="200">
                <template slot-scope="scope">
                  {{
                    scope.row.updateBy|| "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="金额" prop="ompStatus">
                <template slot-scope="scope">
                  {{ scope.row.amount || 0 }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button @click="infoHandle(scope.row, 1)" type="text"
                    >查看</el-button
                  >
                  <el-button
                    @click="infoHandle(scope.row, 2)"
                    type="text"
                    >编辑</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="back-table-page">
            <div class="back-table-page-container">
              <el-pagination
                background
                :current-page="formData.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="formData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <Handle v-if="visable" ref="handle" @refreshDataList="getData" />
    <AddModel v-if="addVisable" ref="addmodel" @refreshDataList="getData1"/>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import "./Index.less";
import moment from "moment";
import Handle from "./components/handle-model";
import AddModel from './components/AddModel'
export default {
  name: "BackJobIndex",
  components: {
    Handle,
    AddModel
  },
  data() {
    return {
      list: [],
      total: 0,
      visable: false,
      loading: false,
      addVisable: false,
      formData: {
        sn: null,
        status: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        time: null,
        orderBy: null,
        organId: null,
        type: null,
      },
      activeName: "first",
    };
  },
  mounted() {
    this.formData.organId = localStorage.getItem("organId");
    this.formData.sn = this.$route.params.sn;
    this.getData();
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name;
      if (tab.name === "first") {
        this.getData();
      } else {
        this.getData1();
      }
    },
    //获取列表数据
    getData() {
      this.loading = true;
      axios
        .post(`/api/faultJob/query`, this.formData)
        .then((res) => {
          const { list, total } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    //查询费用列表
    getData1() {
      this.loading = true;
      axios
        .post(`/api/expenseRecord/query`, this.formData)
        .then((res) => {
          const { list, total } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    addHandle() {
      this.addVisable = true;
      this.$nextTick(() => {
        this.$refs.addmodel.init();
        this.$refs.addmodel.title = '新增费用记录';
      });
    },
    infoHandle(row, type) {
      this.addVisable = true;
      this.$nextTick(() => {
        this.$refs.addmodel.init();
        this.$refs.addmodel.title = '查看费用记录';
        this.$refs.addmodel.type = type
        this.$refs.addmodel.dataForm.id = row.id
      });
    },
    //重置
    resetFrom() {
      this.formData = {
        sn: null,
        status: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        time: null,
        orderBy: null,
        organId: localStorage.getItem("organId"),
        type: null,
      };
      if (this.activeName === "first") {
        this.getData();
      } else {
        this.getData1();
      }
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.search.startTime = this.DateToStr(data[0]);
        this.search.endTime = this.DateToStr(data[1]);
      } else {
        this.search.startTime = null;
        this.search.endTime = null;
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },

    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      this.getData();
    },
    //跳转详情
    setHandle(item) {
      this.visable = true;
      this.$nextTick(() => {
        this.$refs.handle.init();
        this.$refs.handle.title = item.sn + " 回调处理";
        this.$refs.handle.dataForm.sn = item.sn;
        this.$refs.handle.dataForm.cloudId = item.cloudId;
        this.$refs.handle.dataForm.id = item.id;
      });
    },
    backHandle(row) {
      this.$confirm(`确认回调设备吗, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        axios
          .post(`/api/faultJob/callback`, { id: row.id })
          .then((rs) => {
            this.getData();
            return this.$message.success(rs);
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    }
  },
  filters: {
    statusStr(value) {
      if (value !== null || value !== undefined) {
        if (value === 1) {
          return "已处理";
        } else if (value === 0) {
          return "未处理";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    typeStr(value) {
      if(value !== null || value !== undefined) {
        if (value === 1) {
          return "物流费";
        } else if (value === 2) {
          return "维修费";
        } else if(value === 2) {
          return "配件费";
        }  else {
          return "-";
        }
      } else {
        return '-'
      }
    }
  },
};
</script>